import React from 'react';
import instrucciones from '../../assets/images/instrucciones.png';
import '../../assets/styles/components.scss';

export default function Instrucciones() {
    return (
        <div id="instrucciones">
            <img src={instrucciones} />
        </div>
    );
}
